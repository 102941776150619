import React from 'react'
import PropTypes from 'prop-types'
import Layout from '../components/Layout'
import { graphql, Link } from 'gatsby'
// import Header from '../components/Header'
import Footer from '../components/Footer'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
//import SectionSubscribe from '../components/SectionSubscribe';
import { v4 } from 'uuid'
import Img from 'gatsby-image'
import Navbar from '../components/Navbar'
import Helmet from 'react-helmet'

export default class BlogListPage extends React.Component {
  render() {
    const { data } = this.props
    if (!data) { return null }
    const { edges: posts } = data.allMarkdownRemark

    return (
      <Layout>
      <div>
      <Helmet
            title="Tithing Articles"
            meta={[
                {property: 'description', content: "Learn what the Bible has to say about giving, tithing, and generosity."},
                {property: 'og:url', content: "https://www.tithes.app/articles/"},
                {property: 'og:title', content: "Tithing Articles"},
                {property: 'og:description', content: "Learn what the Bible has to say about giving, tithing, and generosity."},
                {name: 'twitter:title', content: "Tithing Articles"},
                {name: 'twitter:description', content: "Learn what the Bible has to say about giving, tithing, and generosity."},
            ]}
        />
        <Navbar />
        <section className="section has-background-light">
          <div className="container">
            <div className="columns is-multiline">
            {posts
              .slice(0)
              .map(({ node: post }) => (
                  <div key={v4()} className="column is-one-third">
                    <div className="card">
                      <div className="card-image">
                        <figure className="image">
                          <Link className="has-text-primary" to={post.fields.slug}>
                            <Img
                              alt={"My Well Ministry " + post.frontmatter.title + " image" }
                              fluid={post.frontmatter.image.childImageSharp.fluid}
                            />
                          </Link>
                        </figure>
                      </div>
                      <div className="card-content">
                        <div className="content">
                          <h4 className="title is-size-4">
                            <Link className="has-text-dark" to={post.fields.slug}>
                              {post.frontmatter.title}
                            </Link>
                          </h4>
                          {/* <p>
                            <small className="has-text-dark-secondary">{post.frontmatter.date}</small>
                            <span> | </span>
                            {post.frontmatter.tags.map(tag => (
                                <span><Link to={`/tags/${kebabCase(tag)}/`} key={tag + `tag`}>#{tag}</Link>&nbsp;</span>
                            ))}
                          </p> */}
                          <p>
                            {post.frontmatter.description}
                          </p>
                          <p>
                            <Link className="text-link" style={{borderBottom: 'none'}} to={post.fields.slug}>
                              <span>Read Article</span>
                              <span className="icon is-medium"><FontAwesomeIcon icon="long-arrow-alt-right" size="sm" /></span>
                            </Link>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>

              ))}
            </div>
          </div>
        </section>
        <Footer/>
      </div>
      </Layout>
    )
  }
}

BlogListPage.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
}

export const imageSettings2x1 = graphql`
fragment imageSettings2x1 on File {
  publicURL
  childImageSharp{
    fluid(maxWidth: 2000) {
        ...GatsbyImageSharpFluid
    }
  }
}`

export const pageQuery = graphql`
  query BlogListQuery {
    allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] },
      filter: { frontmatter: { templateKey: { eq: "blog-post" } }}
    ) {
      edges {
        node {
          excerpt(pruneLength: 200)
          id
          fields {
            slug
          }
          frontmatter {
            title
            description
            image {
              ...imageSettings2x1
            }
            templateKey
            date(formatString: "MMM DD, YYYY")
            tags
          }
        }
      }
    }
  }
`
