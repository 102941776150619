import React from 'react'
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from 'gatsby'

// const styles = {
//   background: "url('/assets/images/img.jpg')center center; background-size:cover"
// }

const footerStyles = {
  paddingTop: "2rem",
  paddingBottom: '2rem',
//   marginTop: '4rem'
}

const Footer = ({title,subtitle}) => (
  <footer className="footer is-size-6 has-text-footer" style={footerStyles}>
    <div className="container">
      <div className="columns">
        <div className="column has-text-centered-mobile">
          <p><strong>&copy; 2021 Tithes.App</strong>&nbsp;|&nbsp;Made with ♥ by <a href="https://www.newculture.co/consulting/?utm_campaign=Tithes&utm_source=tithes.app">NewCulture Consulting</a></p>
        </div>
        <div className="column has-text-right has-text-centered-mobile">
            <Link to="/">
            Tithe Calculator
            </Link>
            
            <Link to="/articles" style={{marginLeft: '20px'}}>
            Articles
            </Link>
        </div>
      </div>
    </div>
  </footer>
)

export default Footer
